<template>
  <div id="accounts-list">
    <div class="header">
      <p class="title">Contas</p>
      <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
        <b-button
          variant="primary"
          @click="addNewCNPJ"
          :disabled="disableButton"
        >
          Adicionar CNPJ
        </b-button>
      </span>
      <b-tooltip v-if="disableButton" target="disabled-wrapper"
        >Atingiu o limite de empresas cadastras para emissão de NF.</b-tooltip
      >
    </div>

    <div class="body">
      <b-table-simple
        id="accounts-table"
        responsive
        borderless
        fixed
        class="table"
      >
        <thead>
          <th>Nome/CNPJ</th>
          <th>Status</th>
          <th>Editar</th>
          <th>Excluir</th>
        </thead>
        <tbody v-if="companies.length > 0">
          <tr v-for="account in companies" :key="account.id">
            <td class="text-align-center">
              <p class="m-0">{{ account.razao_social }}</p>
              <p class="m-0">{{ cnpjFormated(account.cnpj) }}</p>
            </td>
            <td class="status-item">
              <toggle-button
                :sync="true"
                :height="24"
                v-model="account.ativo"
                @input="setStatusAccount(account)"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              />

              <p class="mx-2 my-0">{{ account.ativo ? 'ativo' : 'inativo' }}</p>
            </td>
            <td>
              <Edit class="icon edit-icon" @click="editAccount(account.id)" />
            </td>
            <td>
              <Trash class="icon" @click="deleteAccount(account.id)" />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <p class="text-center my-4 table-empty-state">
            Não há conta cadastrada
          </p>
        </tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/modules/nfe/api'
import { cnpjFormated } from '@/utils/dataHelper.js'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Edit: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/delete-nf.svg')
  },
  computed: {
    ...mapState('nfe', ['CNPJForm', 'showCNPJForm', 'isEdit', 'companies']),
    disableButton() {
      return this.hasReachedLimitCompanies
    }
  },
  data() {
    return {
      registeredAccounts: [],
      clinic: getCurrentClinic(),
      hasReachedLimitCompanies: false
    }
  },
  async mounted() {
    const isLoading = this.$loading.show()

    const { data } = await api.hasReachedLimitCompanies(this.clinic.id)
    this.hasReachedLimitCompanies = data
    try {
      if(this.companies.length === 0) {
        await this.getCompanies(this.clinic.id)
      }
    } catch (err) {
      this.$toast.error(err.message)
    } finally {
      isLoading.hide()
    }
  },
  methods: {
    ...mapActions('nfe', [
        'getCompanies', 'updateCompany'
    ]),
    cnpjFormated,
    addNewCNPJ() {
      this.$store.dispatch('nfe/updateIsEdit', false)
      this.$store.dispatch('nfe/updateShowCNPJForm', true)
    },
    async setStatusAccount(account) {
      const isLoading = this.$loading.show()

      try {
        const data = { ativo: account.ativo }
        await this.updateCompany({id: account.id, data})
        this.$toast.success(`Status da Empresa ${account.razao_social} atualizado com sucesso!`)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    /**
     * @todo need a refactoring
     * @param id
     * @returns {Promise<void>}
     */
    async editAccount(id) {
      const isLoading = this.$loading.show()

      try {
        const { data } = await api.getCompany(id)

        this.$store.dispatch('nfe/updateCNPJForm', data)
        this.$store.dispatch('nfe/updateIsEdit', true)
        this.$store.dispatch('nfe/updateShowCNPJForm', true)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    /**
     * @todo need a refactoring
     * @param id
     * @returns {Promise<void>}
     */
    async deleteAccount(id) {
      const res = await this.$swal({
        title: '',
        html: `<div class="body-alert">Ao deletar esta conta, essa ação <b>não poderá ser desfeita!</b> Deseja realmente cancelar esse CNPJ?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        closeOnClickOutside: false,
        reverseButtons: true
      })

      if (res.isConfirmed) {
        const isLoading = this.$loading.show()

        try {
          const { data } = await api.getCompany(id)

          if (!data.ativo) {
            await api.deleteCompany(id)
            await this.getCompanies(getCurrentClinic().id)

            this.$toast.success('Conta deletada com sucesso!')
          } else {
            this.$toast.error('Não é possível deletar uma conta ativa')
          }
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          isLoading.hide()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
@import '@/assets/scss/table.scss';

#accounts-list {
  .table {
    thead {
      color: var(--dark-blue);
      background: var(--neutral-200);

      th:first-child {
        width: 80%;
      }
    }

    tbody {
      color: var(--type-active);

      td {
        border: none;

        &:not(:first-child) {
          text-align: center;
          padding-left: 0;
        }

        &.status-item {
          display: flex;
          padding-left: 0;
          position: relative;
          right: 1rem;
        }
      }

      .table-empty-state {
        position: relative;
        left: 10rem;
      }
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .edit-icon {
    stroke: var(--neutral-500);
  }
}
</style>
